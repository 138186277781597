import React from 'react';
import {Helmet} from 'react-helmet';

import Footer from '../Base/Footer'
import Header from '../Base/Header'
import Content from './AboutContent'

import aboutStyle from './About.css';

const aboutPageController = (props) => {
    return (
        <div className={aboutStyle.aboutPage}>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="Description" content="Formed in 2018 by our Musical Director, Richard McVeigh, we are a London-based chamber choir formed of 24 experienced singers who specialise in sacred music from across the centuries."/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <link rel="canonical" href="https://www.consortsw1.com/About" />
                <title>Consort SW1 - About</title>
            </Helmet>
            <Header></Header>
            <div className="main">
                <Content></Content>
            </div>
            <Footer></Footer>
        </div>
    );
};

export default aboutPageController;