import React from 'react';
import {Helmet} from 'react-helmet';

import Footer from '../Base/Footer'
import Header from '../Base/Header'
import Content from './HomeContent'

import homeStyle from './Home.css';

export default class homePageController extends React.Component {
    render() {
        return (
            <div className={homeStyle.homePage}>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <meta name="Description" content="Formed in 2018 by our Musical Director, Richard McVeigh, we are a London-based chamber choir formed of 24 experienced singers who specialise in sacred music from across the centuries."/>
                    <meta name="viewport" content="width=device-width, initial-scale=1"/>
                    <link rel="canonical" href="https://www.consortsw1.com" />
                    <title>Consort SW1</title>
                </Helmet>
                <Header></Header>
                <div className="main">
                   <Content></Content> 
                </div>
                <Footer></Footer>
            </div>
        );
    } 
}