import React from 'react';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import YouTubeItem from './YouTubeItem'

import MediaStyle from './Media.css';

class youTubeList extends React.Component {
    state = {
        list: null,
        loading: true,
        currentVideo: 'YurtfaM8Gc8'
    };

    async componentDidMount() {
        const url = "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PL7xm8Mh472-f2cUd8WDmcfMQPO-LGrZwY&key=AIzaSyC4-jmHfz9L20lWkcCxPiBlqy6HWRTGDek"
        const response = await fetch(url);
        const data = await response.json();
        this.setState({ list: data['items'], loading: false, currentVideo: data['items'][0]['snippet']['resourceId']['videoId']});
    }

    videoChangeHandler(e) {
        e.preventDefault();
        if (e.target.id !== null && e.target.id !== '') {
            this.setState({ currentVideo : e.target.id });
        }
    }

    render() {
        const NextButton = ({currentSlide, slideCount, ...props}) => ( <FontAwesomeIcon {...props} icon={['fas', 'arrow-alt-circle-right']}></FontAwesomeIcon> )
        const PrevButton = ({currentSlide, slideCount, ...props}) => ( <FontAwesomeIcon {...props} icon={['fas', 'arrow-alt-circle-left']}></FontAwesomeIcon> )
        const settings = {
            dots: false,
            infinite: true,
            nextArrow: <NextButton></NextButton>,
            prevArrow: <PrevButton></PrevButton>,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 479,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };
        return (
            <div>
                <iframe className={MediaStyle.youTubeIframe} width="100%" height="600" title="YouTubeFrame" src={"https://www.youtube.com/embed/" + this.state.currentVideo} frameBorder="0" allowFullScreen></iframe>
                    {
                        this.state.loading || !this.state.list ? (
                            null
                        ) : [ (
                            this.state.list < 3 ? 
                                ( 
                                    <Slider {...settings}>
                                        {
                                            this.state.list.map((item, index) =>
                                                <YouTubeItem item={item} key={index} handler={this.videoChangeHandler.bind(this)}/>
                                            )
                                        }
                                    </Slider>
                                ) : (
                                    <div className={MediaStyle.nonSliderList}> 
                                        {
                                            this.state.list.map((item, index) =>
                                                <YouTubeItem item={item} key={index} handler={this.videoChangeHandler.bind(this)}/>
                                            )
                                        }
                                    </div>
                                )
                            ) ]
                    }
          </div>
        );
    }  
}

export default youTubeList;