import React from 'react';

import ContactForm from './ContactForm'

import contactStyle from './Contact.css';

const contactContent = (props) => {
    return (
        <div className={contactStyle.contactContent}>
            <div className="page">  
                <h2>Contact</h2>
            </div>
            <div className="page full"> 
                <ContactForm/>
            </div>
        </div>
    );
};

export default contactContent;