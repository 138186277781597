import React from 'react';
import { Link } from "react-router-dom";
import {Helmet} from 'react-helmet';

import Footer from './Footer'
import Header from './Header'

import noMatchStyle from './NoMatch.css';

const noMatchPage = (props) => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="Description" content="Formed in 2018 by our Musical Director, Richard McVeigh, we are a London-based chamber choir formed of 24 experienced singers who specialise in sacred music from across the centuries."/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <title>Consort SW1 - 404</title>
            </Helmet>
            <Header></Header>
                <div className="main">
                    <div className="page">
                        <div className={noMatchStyle.noMatch}>
                            <h2>404 - Page not found</h2>
                            <svg width="500px" id="right_xA0_Image_1_" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 853.92 691.2">
                                <path d="M231.84,692.64c2.89-60.14,5.78-120.28,8.66-180.42c3.17-66.13,6.36-132.25,9.5-198.38c1.89-39.77,3.86-79.54,5.4-119.33 c0.25-6.35,2.57-9.81,7.63-13.55c35.47-26.26,70.59-52.99,105.83-79.57c18.38-13.87,36.85-27.62,55.08-41.7 c3.77-2.91,6.12-2.68,9.82,0.12c53.72,40.71,107.55,81.28,161.45,121.74c3.6,2.71,5.15,5.47,5.36,10.04 c4.07,90.82,8.35,181.63,12.53,272.44c3.51,76.2,6.95,152.4,10.42,228.6c-39.84,0-79.68,0-119.52,0c0.05-1.2,0.18-2.39,0.15-3.59 c-1.39-77.95-2.78-155.89-4.23-233.84c-0.04-2.1-0.55-4.22-1.02-6.28c-4.02-17.68-12.86-32.24-26.85-44.03 c-17.49-14.74-37.86-17.43-58.95-13.51c-18.35,3.41-32.81,14.45-43,30.28c-7.8,12.13-12.98,25.13-13.01,40.02 c-0.04,23.26-0.76,46.53-1.2,69.79c-1.03,53.72-2.06,107.44-3.1,161.16C312.48,692.64,272.16,692.64,231.84,692.64z M409.14,200.99 c0,40.88,0,81.04,0,121.35c13.48,0,26.31,0,39.72,0c0-40.56,0-80.7,0-121.61c18.67,0,36.78,0,54.78,0c0-11.41,0-22,0-33.41 c-18.92,0-37.02,0-55.96,0c0-16.3,0-31.77,0-47.36c-13.02,0-25.17,0-38.43,0c0,15.94,0,31.61,0,47.88c-19.03,0-36.95,0-55.58,0 c0,11.24,0,21.83,0,33.16C372.2,200.99,390.36,200.99,409.14,200.99z"/>
                                <path d="M432,1.44c7.78,6.24,15.39,12.69,23.37,18.66c8.74,6.55,17.86,12.58,26.73,18.96c14.68,10.56,29.36,21.15,43.93,31.87 c10.95,8.06,21.66,16.44,32.59,24.52c20.88,15.43,41.86,30.72,62.75,46.14c10.56,7.79,21,15.73,31.48,23.63 c8.71,6.56,17.39,13.16,26.83,20.31c-2.35,0.28-4.07,0.65-5.8,0.66c-13.68,0.05-27.36,0.22-41.03-0.12 c-3.04-0.08-6.48-1.44-8.98-3.25c-20.63-14.95-41.13-30.07-61.56-45.29c-14.73-10.97-29.23-22.23-43.92-33.25 c-21.16-15.88-42.42-31.61-63.57-47.5c-7.06-5.3-13.99-10.78-20.84-16.35c-2.85-2.32-5.38-3.81-8.88-1.16 c-27.57,20.87-55.11,41.8-82.83,62.48c-24.24,18.09-48.77,35.81-73.04,53.87c-11.87,8.83-23.38,18.14-35.23,27.01 c-2.33,1.74-5.43,3.36-8.21,3.42c-14.63,0.34-29.27,0.17-43.91,0.14c-1.1,0-2.19-0.3-4.61-0.65c30.5-22.39,60.03-43.95,89.42-65.71 c16.48-12.2,32.61-24.89,49.11-37.08c13.38-9.89,27.15-19.27,40.6-29.08c13.87-10.11,27.59-20.44,41.33-30.73 c9.53-7.13,19.01-14.34,28.51-21.52C428.16,1.44,430.08,1.44,432,1.44z"/>
                                <path d="M641.49,644.47c-6.43-125.31-12.87-250.72-19.36-377.22c2.24,1.08,3.87,1.67,5.31,2.57 c65.67,41.5,131.32,83.05,197.01,124.51c3.26,2.06,5.06,4.24,5.48,8.41c5.01,50.33,10.27,100.64,15.48,150.95 c2.66,25.75,5.35,51.51,8,77.26c0.44,4.27,0.7,8.57,1.1,13.5C783.5,644.47,712.94,644.47,641.49,644.47z"/>
                                <path d="M215.96,643.05c-71.51,0-141.93,0-213.08,0c1.1-11.4,2.1-22.32,3.22-33.22c6.14-59.63,12.3-119.27,18.46-178.9 c1.01-9.78,1.71-19.6,3.18-29.31c0.44-2.89,2.32-6.53,4.69-8.03c66.39-42.21,132.92-84.19,199.43-126.2 c0.58-0.37,1.26-0.58,3.58-1.64C228.93,391.94,222.46,517.18,215.96,643.05z"/>
                            </svg>
                            <p>Would you like to go <Link to="/">home</Link>?</p>
                        </div>
                    </div>
                </div>
            <Footer></Footer>
        </div>
    );
};

export default noMatchPage;