import React, { Component } from 'react';
import { Route, Switch } from 'react-router';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitterSquare, faFacebook, faYoutube, faYoutubeSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faSpinner, faArrowAltCircleLeft, faArrowAltCircleRight, faShareSquare, faChevronDown, faBars, faMapMarkerAlt, faTimesCircle, faCalendarWeek, faPhone, faUsers, faVideo } from '@fortawesome/free-solid-svg-icons';
import HomePageController from './components/Home/HomePageController'
import AboutPageController from './components/About/AboutPageController'
import ContactPageController from './components/Contact/ContactPageController'
import EventPageController from './components/Events/EventPageController'
import MediaPageController from './components/Media/MediaPageController'
import noMatchPage from './components/Base/NoMatchPage'

import './main.css';

library.add(faVideo, faUsers, faPhone, faCalendarWeek, faTimesCircle, faMapMarkerAlt, faSpinner, faTwitterSquare, faChevronDown, faFacebook, faYoutubeSquare, faYoutube, faInstagram, faArrowAltCircleRight, faArrowAltCircleLeft, faShareSquare, faBars)

class App extends Component {
  render() {
    return (
          <div className="App">
            <Switch>
                <Route exact path="/" component={HomePageController} />
                <Route exact path="/Events" component={EventPageController} />
                <Route exact path="/Media" component={MediaPageController} />
                <Route exact path="/Video" component={MediaPageController} />
                <Route exact path="/Photos" component={MediaPageController} />
                <Route exact path="/About" component={AboutPageController} />
                <Route exact path="/Contact" component={ContactPageController} />
                <Route component={noMatchPage} />
            </Switch>
          </div>
    );
  }
}

export default App;