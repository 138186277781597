import React from 'react';
import Popup from "reactjs-popup";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MediaStyle from './Media.css';

export default class facebookPhoto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            loading: true,
            width: props.width,
            open: false
        };
    }

    componentWillMount(){
        this.setState({width: window.innerWidth});
    }
    
    async componentDidMount() {
        this.setState({ image: this.props.image, loading: false});
    }

    handleClick(url) {
        window.open("//www.facebook.com/sharer/sharer.php?u=" + url, 'sharer', 'toolbar=0,status=0,width=500,height=360');
    }

    openModal() {
        this.setState({ open: true });
    }

    closeModal() {
        this.setState({ open: false });
    }

    getImageStyle(url) {
        return {
             backgroundImage: 'url(' + url + ')'
         }
     }

    render() {
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        return (
            <div className="photo" key={this.props.index}>
            {
                this.state.loading || !this.state.image ? (
                    <div>
                        <FontAwesomeIcon icon={['fas', 'spinner']} pulse></FontAwesomeIcon>
                    </div>
                ) : (
                    <div>
                    {
                        this.state.width > 991 ? (
                            <div onClick={this.openModal} className={MediaStyle.image} style={this.getImageStyle(this.state.image[6].source)}> 
                            <Popup open={this.state.open} closeOnDocumentClick onClose={this.closeModal}>
                                <div className={MediaStyle.iframeContainer}>
                                    <button className={MediaStyle.close} onClick={this.closeModal}><FontAwesomeIcon icon={['fa', 'times-circle']}></FontAwesomeIcon></button>
                                    <img className={MediaStyle.ImageIframe} src={this.state.image[3].source} alt="galleryImage" />
                                </div>
                            </Popup>
                            </div>
                        ) : (
                            <img className={MediaStyle.fullWidthImage} src={this.state.image[4].source} alt="galleryImage" />
                        )
                    }
                    </div>   
                )
            }
            </div>
        );
    } 
}
