import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import footerStyle from './Footer.css';

const footer = (props) => {
    return (
        <footer>
            <div className={footerStyle.footerPage}>
                <div className={footerStyle.centerBlock}>
                    <Link className={footerStyle.facebook} aria-label="Facebook" rel="noreferrer" target="_blank" to="//www.facebook.com/consortsw1"><FontAwesomeIcon icon={['fab', 'facebook']} /></Link>
                    <p>Consortsw1.com</p> 
                    <Link className={footerStyle.youtube} aria-label="Youtube" rel="noreferrer" target="_blank" to="//www.youtube.com/channel/UCkdRXZZXDmLJM6XBnUpIoog"><FontAwesomeIcon icon={['fab', 'youtube-square']} /></Link>
                </div>
            </div>
        </footer>
    );
};

export default footer;