import React from 'react';
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import aboutStyle from './About.css';

import aboutImage from './about_image.jpeg'
import richardImage from './richard.jpg'

export default class aboutContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {width: props.width, open: false};
    }

    componentWillMount(){
        this.setState({width: window.innerWidth});
    }

    openModal() {
        this.setState({ open: true });
    }

    closeModal() {
        this.setState({ open: false });
    }
    
    render() {
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        return (
            <div className={aboutStyle.aboutContent}>
                <div className="page">
                    <div className={aboutStyle.aboutChoir}>
                        <h2>About the choir</h2>
                        <p>Formed in 2018 by our Musical Director, we are a London-based chamber choir formed of 24 experienced singers who specialise in sacred music from across the centuries.</p>
                        <p>Too many choirs are trying to achieve that perfect ‘straight’ sound in their performances, created out of an era where so many recordings are created to perfection.</p>
                        <p>We don’t buy this.</p>
                        <p>The most passionate music making is in the moment. It’s live. It’s spontaneous. It’s exciting. This is what we’re about – passionate and live performances with discipline and musicianship.</p>
                    </div>
                    <img onClick={this.openModal} className={aboutStyle.aboutImage} src={aboutImage} alt="aboutImage" />
                    {
                        this.state.width > 991 ? (
                            <Popup open={this.state.open} closeOnDocumentClick onClose={this.closeModal}>
                                <div className={aboutStyle.iframeContainer}>
                                    <button className={aboutStyle.close} onClick={this.closeModal}><FontAwesomeIcon icon={['fa', 'times-circle']}></FontAwesomeIcon></button>
                                    <img className={aboutStyle.aboutImageIframe} src={aboutImage} alt="aboutImage" />
                                </div>
                            </Popup>
                        ) : (
                            null
                        )
                    }
                    
                    <div className={aboutStyle.aboutDirector}>
                        <h2>Musical Director</h2>
                        <img className={aboutStyle.richardImage} src={richardImage} alt="aboutImage" />
                        <p>Hi, I’m Richard and I’m the Musical Director of Consort SW1.</p>
                        <p>Being brought up as a chorister, I was introduced to the intoxicating sound world of singers, organs and acoustics at a very early age. The first CD I ever bought was a recording of St Paul’s Cathedral Choir singing settings of the Magnificat and Nunc Dimittis under the inspirational late John Scott. I was hooked to choral and organ music, and have never looked back.</p>
                        <p>Before pursuing a career in other areas, I was a full-time cathedral organist having held positions at Sheffield Cathedral, Chester Cathedral, York Minster and Winchester Cathedral. I’m now Director of Music at St John’s Church in New Alresford near Winchester.</p>
                        <p>Music within the liturgy is my passion, both in terms of organ and choral. My greatest musical inspirations are Barry Rose, John Scott, Harry Christophers, Andrew Nethsingha and Stephen Layton who have moulded their choirs into some of the most musical and disciplined groups on the circuit. I try to model my sound based on these choirs.</p>
                        <p>Forming Consort SW1 and working with its singers is a real joy for me. It allows me to work with some of the best singers in central London, and allows us as a group to perform wonderful music together in some incredible spaces.</p>
                    </div>
                </div>
            </div>
        );
    }
}