import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MediaStyle from './Media.css';

export default class youTubeItem extends React.Component {
    state = {
        item: null,
        loading: true
    };

    async componentDidMount() {
        this.setState({ item: this.props.item, loading: false});
    }

    getImageStyle(url) {
       return {
            backgroundImage: 'url(' + url + ')'
        }
    }

    render() {
        return (
            <div key={this.props.key}>
            {
                this.state.loading || !this.state.item ? (
                    <div>
                        <FontAwesomeIcon icon={['fas', 'spinner']} pulse></FontAwesomeIcon>
                    </div>
                ) : (
                    <button onClick={(e) => this.props.handler(e)} style={this.getImageStyle(this.state.item.snippet.thumbnails.medium.url)} className={MediaStyle.videoLink} id={this.state.item.snippet.resourceId.videoId}>
                        <FontAwesomeIcon className={MediaStyle.videoIcon} icon={['fab', 'youtube']}></FontAwesomeIcon>
                    </button>
                )
            }
            </div>
        );
    } 
}
