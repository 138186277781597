import React from 'react';
import { NavLink } from "react-router-dom";

import menuStyle from './Menu.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class menu extends React.Component {
    constructor() {
        super();
        this.state = {
            classes: menuStyle.nav
        }
    }
    
    clickHandler() {
        let lClasses = this.state.classes
        if (this.state.classes.includes(menuStyle.active)) {
            lClasses = lClasses.replace(menuStyle.active,"")
        } else {
            lClasses = lClasses + " " + menuStyle.active
        }
        this.setState({ classes: lClasses});
    }

    render() {
        return (
            <div className={menuStyle.navWrapper}>
                <span className={menuStyle.mobileButton} onClick={this.clickHandler.bind(this)} ><FontAwesomeIcon icon={['fas', 'bars']}></FontAwesomeIcon></span>
                <ul className={this.state.classes}>
                    <li><NavLink activeClassName={menuStyle.isActive} to="/About"><span>About Us</span></NavLink></li>
                    <li><NavLink activeClassName={menuStyle.isActive} to="/Media"><span>Media</span></NavLink></li>
                    <li><NavLink activeClassName={menuStyle.isActive} to="/Events"><span>Events</span></NavLink></li>
                    <li><NavLink activeClassName={menuStyle.isActive} to="/Contact"><span>Contact</span></NavLink></li>
                </ul>
            </div>
        );
    }
};