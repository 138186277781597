import React, { Component } from 'react';
import qs from 'qs';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

import contactStyle from './Contact.css';

const liveSiteKey= "6LfOpJwUAAAAANrgdQ84k0BfNn2cNzFweUGsU-zg"
let recaptchaInstance;

class contactForm extends Component {
     
    constructor() {
        super()

        this.state = {
            fname: '',
            fnameClass: '',
            fnameValid: false,

            lname: '',
            lnameClass: '',
            lnameValid: false,

            email: '',
            emailClass: '',
            emailValid: false,

            subject: '',
            subjectClass: '',
            subjectValid: false,

            msg: '',
            msgClass: '',
            msgValid: false,

            showAlertBox: false,
            alertText: '',
            isVerified: false,
            success: false,
            submitButtonText: 'Submit',
            disableSubmit: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.verifyCallback = this.verifyCallback.bind(this)
    }

    handleChange = (event) => {
        let inputName = event.target.name;
        this.setState({ [inputName]: event.target.value }, () => this.validateForm(inputName)); 
    }

    verifyCallback (response) {
        if (response) {
            this.setState({ isVerified: true })
        }
    }

    validateForm = (formName) => {
        switch(formName) {
            case 'fname':
                if (this.state.fname) { 
                    this.setState({fnameClass: 'valid', fnameValid: true});
                } else {
                    this.setState({fnameClass: 'invalid', fnameValid: false});
                }
                break;
            case 'lname':
                if (this.state.lname) { 
                    this.setState({lnameClass: 'valid', lnameValid: true});
                } else {
                    this.setState({lnameClass: 'invalid', lnameValid: false});
                }
                break;
            case 'email':
                if (this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {  
                    this.setState({emailClass: 'valid', emailValid: true});
                } else {
                    this.setState({emailClass: 'invalid', emailValid: false});
                }
                break;
            case 'subject':
                if (this.state.subject) { 
                    this.setState({subjectClass: 'valid', subjectValid: true});
                } else {
                    this.setState({subjectClass: 'invalid', subjectValid: false});
                }
                break;
            case 'msg':
                if (this.state.msg) { 
                    this.setState({msgClass: 'valid', msgValid: true});
                } else {
                    this.setState({msgClass: 'invalid', msgValid: false});
                }
                break;
            default:
                break;
        }
    }

    async handleSubmit(e) {
        this.setState({success: false})
        e.preventDefault()
        if (!this.state.fnameValid) {
            this.setState({success: false, showAlertBox: true, alertText: 'Please enter a valid first name'});
        } else if (!this.state.emailValid) {
            this.setState({success: false, showAlertBox: true, alertText: 'Please enter a valid email address'});
        } else if (!this.state.subjectValid) {
            this.setState({success: false, showAlertBox: true, alertText: 'Please enter a valid subject'});
        } else if (!this.state.msgValid) {
            this.setState({success: false, showAlertBox: true, alertText: 'Please enter a valid message'});
        } else {
            if (this.state.isVerified) {
                this.setState({ 
                    isVerified: false,
                    showAlertBox: false, 
                    submitButtonText: 'Sending...',
                    disableSubmit: true
                });

                const { fname, lname, email, msg, subject } = this.state
                await axios.post('https://mail.danjbennett.co.uk', qs.stringify({
                    'fname': fname,
                    'lname': lname,
                    'email': email,
                    'subject': subject,
                    'msg': msg
                }))
                this.setState({ 
                    submitButtonText: 'Sent',
                    success: true, 
                    alertText: '',
                    fname: '', 
                    fnameClass: '',
                    fnameValid: false,
                    lname: '', 
                    lnameClass: '',
                    lnameValid: false,
                    email: '',
                    emailClass: '',
                    emailValid: false,
                    subject: '',
                    subjectClass: '',
                    subjectValid: false,
                    msg: '',
                    msgClass: '',
                    msgValid: false
                });

                document.getElementById("contactForm").reset();
                recaptchaInstance.reset();

                setTimeout(() => { 
                    this.setState({ 
                        success: false,
                        submitButtonText: 'Submit',
                        disableSubmit: false
                    });
                }, 2000);
                
            } else {
                this.setState({success: false, showAlertBox: true, alertText: 'Please check the reCaptcha'});
            }
        }
    }


    render() {
        return (
            <div className={contactStyle.formWrap}>
                <form id="contactForm" onSubmit={this.handleSubmit}>
                    <label htmlFor="name">First Name</label>
                    <input className={this.state.nameClass} onChange={this.handleChange} name="fname" id="fname"/>
                    <label htmlFor="name">Last Name</label>
                    <input className={this.state.nameClass} onChange={this.handleChange} name="lname" id="lname"/>
                    <label htmlFor="email">Email</label>
                    <input className={this.state.emailClass} onChange={this.handleChange} name="email" id="email"/>
                    <label htmlFor="subject">Subject</label>
                    <input className={this.state.subjectClass} onChange={this.handleChange} name="subject" id="subject"/>
                    <label htmlFor="message">Message</label>
                    <textarea className={this.state.messageClass} onChange={this.handleChange} name="msg" id="msg"/>
                    <ReCAPTCHA
                        ref={e => recaptchaInstance = e}
                        sitekey={liveSiteKey}
                        onChange={this.verifyCallback}
                    />
                    <button disabled={this.state.disableSubmit} className={ this.state.disableSubmit ? contactStyle.button + ' ' + contactStyle.disabled : contactStyle.button } type="submit">{this.state.submitButtonText}</button>
                </form>
                
                {this.state.showAlertBox ? <div className={ contactStyle.alertBox }><p>{this.state.alertText}</p></div> : null}
                {this.state.success ? <div className={ contactStyle.successBox }><p>Your email has been sent</p></div> : null }
            </div>
        );
    } 
}

export default contactForm;