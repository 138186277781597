import React from 'react';
import Menu from '../Base/Menu'

import headerStyle from './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";

const header = (props) => {
    return (
        <header className={headerStyle.header2}>
            <div className={headerStyle.header}>
                <div className={headerStyle.shareContainer}>
                    <Link className={headerStyle.facebook} rel="noreferrer" target="_blank" to="//www.facebook.com/consortsw1"><FontAwesomeIcon icon={['fab', 'facebook']} /></Link>
                    <Link className={headerStyle.youtube} rel="noreferrer" target="_blank" to="//www.youtube.com/channel/UCkdRXZZXDmLJM6XBnUpIoog"><FontAwesomeIcon icon={['fab', 'youtube-square']} /></Link>
                </div>
                <Link className={headerStyle.logo} to="/">
                    <span className={headerStyle.logoTop}>Consort <span className={headerStyle.red}>SW1</span></span>
                    <span className={headerStyle.logoBottom}>Directed by Richard McVeigh</span>
                </Link>
            </div>
            <Menu></Menu>
        </header>
    );
};

export default header;
