import React from 'react';

import eventStyle from './Events.css';

import FacebookEvents from './FacebookEvents'

const eventContent = (props) => {
    return (
        <div className={eventStyle.eventContent}>
            <div className="page">
                <h2>Events</h2>
                <FacebookEvents></FacebookEvents>
            </div>
        </div>
    );
};

export default eventContent;