import React from 'react';

import eventStyle from './Events.css';

import FacebookEvent from './FacebookEvent'

export default class facebookEvents extends React.Component {
    state = {
        events: null,
        paging: null,
        loading: true,
        url: "https://graph.facebook.com/v12.0/259521348141485?fields=events%7Bis_canceled%2Cname%2Cticket_uri%2Ccover%2Cplace%2Cstart_time%7D&access_token=EAAcoZAI6ncZBwBO3oFpsxg6W1vZBDT3UD1noqFbvLwE3Lf92HdbYOGmN1B6XXZBoKmVZAbJKTeYq1yNEbjLyh7PJ3BTHsfsZBgOxUTS4Q2XFhpR40Aoo6HH7mZBZCkxcMmjCypEXVZCuoyx5K27TzGUszLt1wTMhf9FvZBsEnxCA7r5zgi28aVVW8jZCsKZCtuAR19lC"
    };
    
    async componentDidMount() {
        const response = await fetch(this.state.url);
        const data = await response.json();
        this.setState({ events: data['events']['data'], paging: data['paging'], loading: false});
    }

    render() {
        return (
                <div className={eventStyle.facebookEvents}>
                    {
                        !this.state.events ? (
                            <div>
                                {/* <FontAwesomeIcon className={eventStyle.loadingSvg} icon={['fas', 'spinner']} pulse/> */}
                                <p>There are no upcoming events at this time.</p>
                            </div>
                        ) : (
                            <div>
                                {this.state.events.map((item, index) => 
                                    <FacebookEvent event={item} key={index}/>
                                )}
                            </div>
                        )
                    }
                </div>
        );
    } 
}