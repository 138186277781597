import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import homeStyle from './Home.css';
// import FacebookFeed from './FacebookFeed'

const homeContent = (props) => {
    return (
        <div className="page">
            <div className={homeStyle.homeContent}>
                <h2>Welcome</h2>
                <p className={homeStyle.intro}>Passionate and live performances with discipline and musicianship.</p> 
                <ul className={homeStyle.links}>
                    <li><Link aria-label="About" to="/About">About Us <FontAwesomeIcon icon={['fa', 'users']} /></Link></li>
                    <li><Link aria-label="Media" to="/Media">Media <FontAwesomeIcon icon={['fa', 'video']} /></Link></li>
                    <li><Link aria-label="Events" to="/Events">Events <FontAwesomeIcon icon={['fa', 'calendar-week']} /></Link></li>
                    <li><Link aria-label="Contact" to="/Contact">Contact <FontAwesomeIcon icon={['fa', 'phone']} /></Link></li>
                </ul>
            </div>
            {/* <FacebookFeed></FacebookFeed> */}
        </div>
    );
};

export default homeContent;