import React from 'react';
import {Helmet} from 'react-helmet';

import Footer from '../Base/Footer'
import Header from '../Base/Header'
import Videos from './VideoContent'
import FacebookPhotos from './FacebookPhotos'

const videoPageController = (props) => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="Description" content="Formed in 2018 by our Musical Director, Richard McVeigh, we are a London-based chamber choir formed of 24 experienced singers who specialise in sacred music from across the centuries."/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <link rel="canonical" href="https://www.consortsw1.com/Media" />
                <title>Consort SW1 - Media</title>
            </Helmet>
            <Header></Header>
            <div className="main full">
                <Videos></Videos>
                <FacebookPhotos title="Photos" album="334254524001500"></FacebookPhotos>
            </div>
            <Footer></Footer>
        </div>
    );
};

export default videoPageController;