import React from 'react';

import FacebookPhoto from './FacebookPhoto'

import MediaStyle from './Media.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class facebookPhotos extends React.Component {
    state = {
        feed: null,
        paging: null,
        loading: true,
        url: "https://graph.facebook.com/v12.0/" + this.props.album + "?fields=photos.limit(25)%7Bpicture%2Cimages%7D&access_token=EAAcoZAI6ncZBwBO3oFpsxg6W1vZBDT3UD1noqFbvLwE3Lf92HdbYOGmN1B6XXZBoKmVZAbJKTeYq1yNEbjLyh7PJ3BTHsfsZBgOxUTS4Q2XFhpR40Aoo6HH7mZBZCkxcMmjCypEXVZCuoyx5K27TzGUszLt1wTMhf9FvZBsEnxCA7r5zgi28aVVW8jZCsKZCtuAR19lC"
    };
    
    async componentDidMount() {
        const response = await fetch(this.state.url);
        const data = await response.json();
        this.setState({ feed: data['photos']['data'], paging: data['photos']['paging'], loading: false});
    }

    // async loadMoreFeed() {
    //     if (this.state.paging.next) {
    //         this.setState({loading: true});
    //         const response = await fetch(this.state.paging.next);
    //         const data = await response.json();
    //         var currentFeed = this.state.feed.slice();    
    //         data['data'].map(item => (
    //             currentFeed.push(item) 
    //         ));
    //         this.setState({ feed: currentFeed, paging: data['paging'], loading: false});
    //     } 
    // }

    render() {
        return (
                <div className="page">    
                    <h2>{this.props.title}</h2>
                    {
                        !this.state.feed ? (
                            <div>
                                <FontAwesomeIcon className={MediaStyle.loadingSvg} icon={['fas', 'spinner']} pulse/>
                            </div>
                        ) : (
                            <div className={MediaStyle.GalleryList}> 
                                {
                                    this.state.feed.map((item, index) => 
                                        <FacebookPhoto image={item.images} key={index}/>
                                    )
                                }
                                {
                                    this.state.loading ? (
                                        <div>
                                            <div>
                                                <span>Loading</span><FontAwesomeIcon className={MediaStyle.loadingSvg} icon={['fas', 'spinner']} pulse/> 
                                            </div>
                                        </div>   
                                    ) : ( 
                                        this.state.paging.next ? (
                                            <div>
                                                <button className={MediaStyle.loadButton} onClick={this.loadMoreFeed.bind(this)}>
                                                    <span>More</span><FontAwesomeIcon icon={['fas', 'chevron-down']}></FontAwesomeIcon>
                                                </button>
                                            </div>
                                        ) : ( null )
                                    ) 
                                }
                            </div>
                        )
                    }
                </div>
        );
    } 
}