import React from 'react';

import YouTubeList from './YouTubeList';

import MediaStyle from './Media.css';

const videoContent = (props) => {
    return (
        <div className={MediaStyle.videoContent}>
            <div className="page">
                <h2>Video</h2>
            </div>
            <div className="page full">
                <YouTubeList/>
            </div>
        </div>
    );
};

export default videoContent;