import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from 'react-moment';
import Popup from "reactjs-popup";

import eventStyle from './Events.css';

export default class facebookEvent extends React.Component {
    state = {
        event: null,
        loading: true,
        open: false
    };
    
    openModal() {
        this.setState({ open: true });
    }

    closeModal() {
        this.setState({ open: false });
    }

    async componentDidMount() {
        this.setState({ event: this.props.event, loading: false});
    }

    render() {
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        return (
            <div className={eventStyle.facebookEvent} key={this.index}>
            {
                !this.state.loading || this.state.event ? (
                    <div>
                        {
                            this.state.event.cover ? (
                                <span className={eventStyle.image}>
                                     <a target="_blank" href={"https://www.facebook.com/events/" + this.state.event.id} rel="noopener noreferrer"><img src={this.state.event.cover.source} alt="fbEventImg"/></a>
                                </span>
                            ) : (
                                null
                            )
                        }
                        <span className={eventStyle.eventDay}>
                            <span className={eventStyle.day}><Moment format="D">{this.state.event.start_time}</Moment></span>
                            <span className={eventStyle.month}><Moment format="MMM">{this.state.event.start_time}</Moment></span>
                            <span className={eventStyle.time}><Moment format="LT">{this.state.event.start_time}</Moment></span>
                        </span>
                        <a className={eventStyle.eventName} target="_blank" href={"https://www.facebook.com/events/" + this.state.event.id} rel="noopener noreferrer">{this.state.event.name}</a>
                        {
                            this.state.event.ticket_uri ? (
                                <a className={eventStyle.ticketLink} target="_blank" href={this.state.event.ticket_uri} rel="noopener noreferrer">Buy Ticket</a>
                            ) : (
                                <span className={eventStyle.noTicketLink} target="_blank" href={this.state.event.ticket_uri} rel="noopener noreferrer">No Ticket</span>
                            )
                        }
                        <span className={eventStyle.eventPlace}>
                            <span className={eventStyle.placeName}>{this.state.event.place.name} 
                                <button className={eventStyle.locationButton} onClick={this.openModal}><FontAwesomeIcon icon={['fa', 'map-marker-alt']}></FontAwesomeIcon></button>
                            </span>
                            <span className={eventStyle.country}>{this.state.event.place.location.country}</span>
                        </span>
                        <Popup open={this.state.open} closeOnDocumentClick onClose={this.closeModal}>
                            <div className={eventStyle.iframeContainer}>
                                <button className={eventStyle.close} onClick={this.closeModal}><FontAwesomeIcon icon={['fa', 'times-circle']}></FontAwesomeIcon></button>
                                <iframe title="GoogleMapsFrame" className={eventStyle.mapIframe} src={"https://maps.google.com/maps?q=" + this.state.event.place.name + "&coord=" + this.state.event.place.location.latitude + "," + this.state.event.place.location.longitude + "&output=embed&z=17"} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"><a href="https://www.maps.ie/map-my-route/">Map a route</a></iframe>
                            </div>
                        </Popup>
                    </div>
                ) : (
                    null
                )
            }
            </div>
        );
    } 
}